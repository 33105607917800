const de = {
  Slogan: "Echtes Sozialisieren",
  AvailableIn: "Verfügbar in",
  AvailableNow: "Jetzt verfügbar",
  Days: "TAGE",
  Hours: "STD",
  Minutes: "MIN",
  Seconds: "SEK",
  Features: "Funktionen",
  JoinGroupsOfHeader: "JOIN-GRUPPEN MIT INTERESSANTEN MENSCHEN",
  JoinGroupsOfTextOne: "Menschen zu treffen war noch nie einfacher.",
  JoinGroupsOfTextTwo:
    "Sieh dir Gruppen interessanter Menschen an, die sich an einem bestimmten Ort, bei einem großen Event oder einem benutzererstellten Event treffen. Frag, ob du beitreten darfst oder like die Gruppe, damit sie dich einladen können.",
  FriendsAndDatesHeader: "FREUNDE UND DATES, DIE ZU DIR PASSEN",
  FriendsAndDatesTextOne:
    "Dating oder einfach nur sozialisieren – du hast die Wahl.",
  FriendsAndDatesTextTwo:
    "Verpasse nicht die Gelegenheit, Menschen zu treffen, die deinem Typ entsprechen. Menschen mit passenden Eigenschaften und Dating-Vorlieben werden als Social Match angezeigt. Wir lieben es, dich mit den richtigen Menschen für dich zu verbinden!",
  CreateUniqueEventsHeader:
    "ERSTELLE DEINE EIGENEN GRUPPEN UND EINZIGARTIGEN EVENTS",
  CreateUniqueEventsTextOne:
    "Hast du Lust, eine Party zu schmeißen oder vielleicht mit deinen Freunden Fußball zu spielen?",
  CreateUniqueEventsTextTwo:
    "Neben dem Erstellen und Beitreten von Gruppen bei von uns gelisteten Orten und großen Events, kannst du auch deine eigenen, maßgeschneiderten Events erstellen. Mach sie riesig oder halte sie privat, die Entscheidung liegt bei dir.",
  Legal: "Rechtliches",
  PrivacyPolicy: "Datenschutzrichtlinie",
  Terms: "Nutzungsbedingungen",
  CookiePolicy: "Cookie-Richtlinie",
  ContactUs: "Kontakt",
  Download: "Download!",
  AboutUs: "Über uns",
  RealSocializing: "Echtes Sozialisieren",
  WhyToGetJoinHeader: "Warum Join?",
  WhyToGetJoinTextOne:
    "Wir bezweifeln, dass du eine einzige App nutzt, die dein soziales Leben wirklich fördert. \nKein Vorwurf an große Plattformen wie Instagram oder Facebook: Es gibt einige erfolgreiche „soziale“ – aber in Wirklichkeit All-in-One-Plattformen, und das aus gutem Grund. Doch wir bieten eine Dimension des Sozialisierens, die den bisherigen sozialen Apps gefehlt hat.",
  WhyToGetJoinTextTwo:
    "Wir sind stolz darauf, die erste wirkliche soziale App zu sein, denn wir konzentrieren uns ausschließlich darauf, das eine so gut wie möglich zu machen: Menschen im echten Leben zusammenzubringen.\n\nMit Join hast du Zugang zu Gruppen von Menschen, die sich in Gastronomiebetrieben und Freizeiteinrichtungen wie Bars, Nachtclubs, Cafés, Musikevents und mehr verabredet haben. Es gibt auch Partys, Sportveranstaltungen und andere Events, denen du beitreten kannst.\nNeben der Hilfe bei der Planung von Spaß mit deinen aktuellen Freunden ist es unsere oberste Priorität, dir die Möglichkeit zu geben, neue, wertvolle Freundschaften und Dates zu schließen. Vergiss das unangenehme Gefühl – mit Join kannst du die richtigen Menschen ganz unkompliziert treffen. Wir können das nicht in wenigen Sätzen erklären, aber wir haben sichergestellt, dass das Eis endgültig gebrochen ist. Join ist weit entfernt von einer weiteren peinlichen Dating-Plattform, die man ungern nutzt.\n\nLieber Social-App-Nutzer, obwohl Join die frische Luft ist, die du gebraucht hast, brauchen wir dich auch. Wir hoffen, dass du deine Erfahrung auf unserer App genießen wirst.",
  OurHistoryHeader: "Unsere Geschichte – Join & Das Team",
  OurHistoryTextOne:
    "Covid-19 hat die Welt auf den Kopf gestellt. In sozialer Hinsicht hat die Quarantäne in Griechenland das Sozialisieren aus dem Leben der Menschen verbannt und die Gastronomiebranche stark getroffen. Genau hier kommt Join ins Spiel.",
  OurHistoryTextTwo:
    "Die Wahrheit ist, dass unsere zwischenmenschlichen Beziehungen nach der Pandemie nicht mehr dieselben waren. Manche wurden schwächer, manche stärker, und viele wurden ganz aufgegeben.\n\n–Die Menschen haben erkannt, wer ihnen wirklich wichtig ist–\n\nITALIC“An einem langweiligen Abend der Quarantäne 2021 kam mir die Idee, die damals verpassten Bars, Clubs und Cafés irgendwie mit dem Bedürfnis der Menschen nach echten Beziehungen zu verbinden. Das Ergebnis heißt heute Join, eine mobile App im App Store und auf Google Play. Ich bin Dimitris Chatzichristofas, Mitbegründer der App.\n\nITALICDie Entwicklung von Join war kein einfacher Prozess, und deshalb bin ich dankbar, dass ich zwei großartige Entwickler und Mitbegründer, George Bratsiotis und Chris, an meiner Seite hatte, die die App von Grund auf entwickelt haben, während ich die meisten geschäftlichen Aufgaben übernommen habe. Wir haben noch viel Arbeit vor uns und freuen uns auf das, was noch kommt.“",
  FullName: "Vollständiger Name",
  Subject: "Betreff",
  AccountOrPrivacyIssue: "Konto- / Datenschutzproblem",
  ReportInappropriateBehaviour: "Unangemessenes Verhalten melden",
  UserCoumplaint: "Nutzerbeschwerde",
  BusinessEnquiry: "Geschäftsanfrage",
  Other: "Sonstiges",
  Message: "Nachricht",
  SubmitButton: "Absenden",
  INeedHelpCreatingMyGroupQ: "Ich brauche Hilfe beim Erstellen meiner Gruppe.",
  INeedHelpCreatingMyGroupQASectionOne:
    "Als Ersteller deiner eigenen Gruppe hast du die volle Kontrolle, also mach dir keine Sorgen, auch wenn es deine erste Gruppe ist. Hier ist eine einfache Anleitung.",
  DateAndTimeDescrGroups:
    "Hier wählst du das Datum und die Uhrzeit aus, zu der sich deine Gruppe treffen wird. Lass dich nicht von a.m.-Zeiten verwirren: Zum Beispiel mag Sonntag, 00:45 Uhr für dich immer noch Samstagabend sein, aber bei Join ist es Sonntagmorgen, und so musst du es eintragen.",
  InviteYourFriendsDescrGroups:
    "In diesem Schritt kannst du deine Freunde einladen (keine Sorge, du kannst später noch weitere einladen). Eingeladene Mitglieder erhalten von dir eine Einladung, die sie bestätigen müssen, um der Gruppe beizutreten. Du kannst eine Gruppe auch ohne Einladungen erstellen und andere können sich bewerben, um mitzumachen.",
  MakeAStatement: "Setze ein Statement",
  MakeAStatementDescrGroups:
    "Das ist deine Chance, deiner Gruppe eine persönliche Note zu verleihen. Zum Beispiel „Wir feiern meinen Geburtstag“ oder „Mädels, zieht weiße Kleider an“.",
  YourGroupIsReadyForCreation: "Deine Gruppe ist bereit zur Erstellung!",
  INeedHelpCreatingMyEvent: "Ich brauche Hilfe beim Erstellen meines Events.",
  INeedHelpCreatingMyEventASectionOne:
    "Als Ersteller deines eigenen Events hast du die volle Kontrolle, also mach dir keine Sorgen, auch wenn es dein erstes Event ist. Hier ist eine einfache Anleitung.",
  EventTitle: "Event-Titel",
  EventTitleDescrEvents:
    "Das sollte eine kurze Beschreibung deines Events sein. Zum Beispiel „5x5 Fußball in Chalandri“, „Geheime Strandparty“ oder „Entspanntes Treffen am Platz von Agia Paraskevi“.",
  DescribeYourEvent: "Beschreibe dein Event",
  DescribeYourEventDescrEvents:
    "Jetzt hast du die Gelegenheit, dein Event zu beschreiben. Füge alle Details hinzu, die du bei einem Event wie deinem wissen wollen würdest. Wenn du genau bist, werden mehr und passendere Leute Interesse an deinem Event zeigen.",
  PrivateEvent: "Privates Event",
  PrivateEventDescrEvents:
    "Alle benutzerdefinierten Events sind standardmäßig öffentlich, das bedeutet, dass alle Join-Nutzer dein Event sehen und mit ihm interagieren können, außer denen, die du blockiert hast. Wenn du dein Event privat machst, ist der Zugriff nur auf deine Freunde und eingeladene Personen beschränkt. Du kannst die Privatsphäre deines Events auch nach der Erstellung noch ändern.",
  DateAndTime: "Datum und Uhrzeit",
  DateAndTimeDescrEvents:
    "Hier wählst du das Datum und die Uhrzeit, zu der dein Event beginnt. Lass dich nicht von a.m.-Zeiten verwirren: Zum Beispiel mag Sonntag, 00:45 Uhr für dich immer noch Samstagabend sein, aber bei Join ist es Sonntagmorgen, und so musst du es eintragen.",
  Category: "Kategorie",
  CategoryDescrEvents:
    "Wir sind der Meinung, dass dein Event höchstwahrscheinlich in eine dieser Kategorien passt: Sport, Party, Reise oder Treffen. Wenn du dein Event in keiner dieser Kategorien klar einordnen kannst, wähle „Treffen“. Schließlich ist jedes Event ein Treffen.",
  InviteYourFriends: "Lade deine Freunde ein",
  InviteYourFriendsDescrEvents:
    "Dies ist der Schritt, bei dem du zunächst deine Freunde einlädst (keine Sorge, du kannst später noch mehr einladen). Eingeladene Mitglieder erhalten eine Benachrichtigung von dir, die sie bestätigen müssen, um dem Event beizutreten. Du kannst auch ein Event erstellen, ohne jemanden einzuladen, und Leute können anfragen, ob sie beitreten dürfen.",
  YourCustomEventIsReadyForCreation:
    "Dein Event ist jetzt bereit zur Erstellung!",
  CanICreateAPrivateGroupOrJoinAGroupPrivatelyQ:
    "Kann ich eine private Gruppe erstellen oder einer Gruppe privat beitreten?",
  CanICreateAPrivateGroupOrJoinAGroupPrivatelyA:
    "Alle Gruppen bei Orten und verifizierten Events sind öffentlich. Du kannst jedoch dein Profil auf privat stellen, wodurch deine Teilnahme in Gruppen nur deinen Freunden und Mitgliedern dieser Gruppe angezeigt wird.",
  CanICreateAPrivateEventOrJoinAnEventPrivatelyQ:
    "Kann ich ein privates Event erstellen oder einem Event privat beitreten?",
  CanICreateAPrivateEventOrJoinAnEventPrivatelyA:
    "Im Gegensatz zu Gruppen bei Orten und verifizierten Events kannst du private individuelle Events erstellen oder diesen beitreten. Als Ersteller eines individuellen Events kannst du jederzeit zwischen öffentlich und privat wechseln.",
  AmIMakingAreservationWhenCreatingAGroupQ:
    "Mache ich eine Reservierung, wenn ich eine Gruppe erstelle?",
  AmIMakingAreservationWhenCreatingAGroupA:
    "Zurzeit macht Join keine Tischreservierungen für deine Gruppen.",
  IAmHesitantToJoinQ:
    "Ich zögere, Join-Gruppen beizutreten oder zu erstellen, da andere Menschen dann etwas über mein echtes soziales Leben erfahren könnten.",
  IAmHesitantToJoinA:
    "Wir verstehen dein Anliegen bezüglich deiner Privatsphäre und tun unser Bestes, um deine Bedürfnisse zu erfüllen, indem wir dir beispielsweise die Möglichkeit bieten, dein Profil über die App-Einstellungen zu beschränken oder private Events zu erstellen. Join bleibt dennoch eine Plattform, die darauf ausgerichtet ist, Menschen im echten Leben zusammenzubringen.",
  CanIInviteFriendsToSomeoneElsesEventQ:
    "Kann ich Freunde zu einem Event von jemand anderem einladen?",
  CanIInviteFriendsToSomeoneElsesEventA:
    "Ja, das kannst du. Bei privaten Events erhält der Ersteller eine Benachrichtigung über deine Absicht, jemanden einzuladen, und muss dies genehmigen, damit die Einladung durchgeht. Bei offenen Events kann jedes Mitglied, das beigetreten ist, seine Freunde ohne die Zustimmung des Erstellers einladen.",
  IHaveBlockedSomeoneOnJoinQ:
    "Ich habe jemanden auf Join blockiert. Können sie die Gruppen und Events sehen, denen ich beitrete?",
  IHaveBlockedSomeoneOnJoinA:
    "Personen, die du auf Join blockiert hast, können nicht sehen, ob du einer Gruppe oder einem Event beigetreten bist, eines erstellt hast oder zu einem eingeladen wurdest, es sei denn, sie sind ebenfalls mit dieser Gruppe oder diesem Event verbunden.",
  WhatAmISeeingInTheHotPageQ: "Was sehe ich auf der „Hot“-Seite?",
  WhatAmISeeingInTheHotPageA:
    "Der Inhalt von Join besteht aus Gruppen und individuellen Events. Gruppen können unter „Orte“ oder „Verifizierte Events“ erstellt werden, während individuelle Events eigenständige Einheiten sind, die von den Benutzern selbst erstellt und angepasst werden. Wir haben einen Algorithmus entwickelt, der Gruppen und individuelle Events danach filtert, wie gut dein Profil zu den Profilen in ihnen passt, und diese dann auf der Hot-Seite anzeigt.",
  WhyIsJoinAskingForAllThisProfileInfoQ:
    "Warum fragt Join nach all diesen Profilinformationen?",
  WhyIsJoinAskingForAllThisProfileInfoA:
    "Wir respektieren dein Anliegen bezüglich deiner Profilprivatsphäre und bemühen uns, unseren Nutzern unseren einzigen Zweck zu erfüllen: dich im echten Leben mit anderen Menschen zusammenzubringen. Um dies richtig zu erreichen, benötigen wir Informationen über dich, was du gerne machst, wo du studierst oder studiert hast, falls du Student bist, und welche Art von Menschen du bevorzugt triffst.",
  CanIOmitToDiscloseSomeProfileInfoAskedByJoinQ:
    "Kann ich es vermeiden, einige der von Join angeforderten Profilinformationen preiszugeben?",
  CanIOmitToDiscloseSomeProfileInfoAskedByJoinA:
    "Wir raten unseren Nutzern im Allgemeinen davon ab, wichtige Informationen auf Join auszulassen, da dies ihre Erfahrung auf der Plattform beeinträchtigen könnte. Dennoch kannst du in vielen Fällen „Keine“ oder „Andere“ als Antwort auswählen, falls du bestimmte Informationen nicht preisgeben möchtest.",
  WhatISProfileStrengthQ: "Was bedeutet „Profilstärke“?",
  WhatISProfileStrengthA:
    "Die Profilstärke zeigt an, wie aussagekräftig und stark dein Profil auf Join in seinem aktuellen Zustand ist. Sie zeigt nicht an, ob dein Profil vollständig ist, und ist nur für dich sichtbar.",
  DiscoverTheMainIdea: "Entdecke die Idee",
  SeeHowToInteract: "Lerne die Interaktion",
  MoreAboutEventCreation: "Mehr über die Erstellung von Events",
  ContactUsTitle: "Kontaktiere uns",
  ContactUsSubtitle:
    "Nimm Kontakt auf, um mögliche Probleme zu melden, geschäftliche Anfragen zu stellen oder einfach nur Hallo zu sagen!",
  SomethingAboutUs: "Etwas über uns",
  SiteTitle: "Join | Gruppen, Events & Menschen",
  AppDescription:
    "Join ist eine mobile App, die sich ganz dem echten Sozialleben widmet. Verwende sie, um Gruppen und Veranstaltungen wie Partys und vieles mehr beizutreten. Erstelle ein Konto und lerne Menschen auf eine ganz neue Art kennen.",
  OpenInApp: "In der App öffnen",
};

export default de;
