import React, { useState, useEffect } from "react";
import logo from "../../assets/joinlogo.png";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import platform from "platform";
import Countdown from "react-countdown";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button"; // Import Button
import "../../assets/CountdownTimer.css";
import "../../assets/App.css";
import { useTranslation } from "react-i18next";

export default function FirstSection() {
  const { t, i18n } = useTranslation();
  const os = platform.os.family;
  const [isCountdownFinished, setIsCountdownFinished] = useState(false);
  const [isWebView, setIsWebView] = useState(false);

  const padWithZeros = (number, minLength) => {
    const numberString = number.toString();
    return numberString.length >= minLength
      ? numberString
      : "0".repeat(minLength - numberString.length) + numberString;
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      setIsCountdownFinished(true);
      return !["Android", "iOS"].includes(os) ? (
        <Stack spacing={1} justifyContent="center" direction="row">
          <img
            src="./ios.svg"
            alt="app_store"
            onClick={() => console.log("appstore")}
            style={{ width: 160 }}
          />
          <img
            src="./android.png"
            alt="google_play"
            onClick={() => console.log("playstore")}
            style={{ width: 200 }}
          />
        </Stack>
      ) : (
        <>
          <img
            src={os.toLowerCase() === "android" ? "./android.png" : "ios.svg"}
            alt="google_play"
            onClick={() => console.log("playstore")}
            style={{ width: 200 }}
          />
        </>
      );
    } else {
      return (
        <Stack spacing={4} justifyContent="center" direction="row">
          <Stack spacing={1} alignItems="center">
            <span className="value">{padWithZeros(days, 2)}</span>
            <span className="label">{t("Days")}</span>
          </Stack>
          <Stack spacing={1} alignItems="center">
            <span className="value">{padWithZeros(hours, 2)}</span>
            <span className="label">{t("Hours")}</span>
          </Stack>
          <Stack spacing={1} alignItems="center">
            <span className="value">{padWithZeros(minutes, 2)}</span>
            <span className="label">{t("Minutes")}</span>
          </Stack>
          <Stack spacing={1} alignItems="center">
            <span className="value">{padWithZeros(seconds, 2)}</span>
            <span className="label">{t("Seconds")}</span>
          </Stack>
        </Stack>
      );
    }
  };

  const handleCountdownComplete = () => {
    setIsCountdownFinished(true);
  };

  const detectWebView = () => {
    const userAgent = navigator.userAgent || window.opera;
    if (
      userAgent.includes("FBAN") || // Facebook App
      userAgent.includes("FBAV") || // Facebook Messenger App
      userAgent.includes("Instagram") || // Instagram App
      userAgent.includes("Line") || // Line App
      userAgent.includes("Snapchat") // Snapchat App
    ) {
      setIsWebView(true);
    }
  };

  useEffect(() => {
    detectWebView();
  }, []);

  const handleOpenInApp = () => {
    const appDeepLink = `join-socialapp:/${window.location.pathname}${window.location.search}`;

    console.log(appDeepLink);

    // Fallback URLs for App Store and Google Play
    const fallbackUrl =
      platform.os.family === "Android"
        ? "https://play.google.com/store/apps/details?id=your.android.app.id" // TODO Replace
        : "https://apps.apple.com/app/idyouriosappid"; // TODO Replace

    window.open(appDeepLink, "_blank", "noopener,noreferrer");

    // setTimeout(() => {
    //   window.location.href = fallbackUrl;
    // }, 1500);
  };

  return (
    <Stack
      spacing={2}
      alignItems="center"
      marginTop={{ xs: 13, sm: 25 }}
      paddingInline={4}
      sx={{
        background: "linear-gradient(to bottom, white, lightblue)", // Gradient
        padding: 4,
        borderRadius: 2,
      }}
    >
      <a
        href={`join-socialapp:/${window.location.pathname}${window.location.search}`}
        target="_system"
      >
        <button>{`join-socialapp:/${window.location.pathname}${window.location.search}`}</button>
      </a>
      {
        <Button
          variant="contained"
          onClick={handleOpenInApp}
          sx={{
            bottom: {
              xs: "20px",
              sm: "40px",
            },
            borderRadius: "20px",
            width: "80%",
            maxWidth: "400px",
            backgroundColor: "#03a9fc",
            "&:hover": {
              backgroundColor: "#B0E0E6",
            },
          }}
        >
          {`${window.location.pathname}${window.location.search}`}
        </Button>
      }

      <img
        className="App-logo"
        src={logo}
        alt="Join Trademark - Mobile app for socializing, joining events, meeting people, and dating"
        title="Trademark of Join - A Mobile App for Real Socializing, Events, and Dating"
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 0,
          textAlign: "center",
          backgroundColor: "transparent",
        }}
      >
        <Typography
          variant="h1"
          component="h1"
          sx={{
            fontSize: { xs: 26, md: 40 },
            fontFamily:
              i18n.language === "el" ? "Manrope Regular" : "Trueno Light",
            letterSpacing: 1,
            color: "#1EABF1",
            textAlign: "center",
          }}
        >
          <pre style={{ fontFamily: "inherit" }}>{t("Slogan")}</pre>
        </Typography>
      </Box>

      <Typography
        variant="body1"
        align="center"
        color="#57606f"
        fontFamily={
          i18n.language === "el" ? "OpenSans MediumItalic" : "Montserrat Italic"
        }
      >
        {isCountdownFinished ? t("AvailableNow") : t("AvailableIn")}
      </Typography>

      <Countdown
        date={new Date(2024, 10, 30)}
        renderer={renderer}
        onComplete={handleCountdownComplete}
      />
    </Stack>
  );
}
